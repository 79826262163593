<template>
  <div id="settings-wrapper">
    <v-card
      id="settings"
      class="py-2 px-4"
      color="rgba(0, 0, 0, .3)"
      dark
      flat
      link
      min-width="100"
      style="position: fixed; top: 0px; right: -35px; border-radius: 8px;"
    >
      <v-icon large>
        mdi-cog
      </v-icon>
    </v-card>
    <v-menu
      v-model="menu"
      :close-on-content-click="false"
      activator="#settings"
      bottom
      content-class="v-settings"
      left
      nudge-left="8"
      offset-x
      origin="top right"
      transition="scale-transition"
    >
      <v-card
        class="text-center mb-0"
        width="300"
        :loading="applyingGlobalFilter"
      >
        <v-card-text>
          <strong class="mb-3 d-inline-block">ACCOUNT FILTERS</strong>

          <v-list
            class="overflow-y-auto text-left"
            max-height="400"
          >
            <v-list-item-group
              v-model="selectedAccount"
              color="primary"
            >
              <v-list-item
                v-for="(item, i) in accounts"
                :key="i"
                :inactive="inactive"
                two-line
              >
                <v-list-item-content @click="selectAccount(item.acct)">
                  <v-list-item-title v-html="item.acct" />
                  <v-list-item-subtitle>Mobile Count: {{ item.count }}</v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
            </v-list-item-group>
          </v-list>

          <!-- <v-overlay
            :absolute="absolute"
            :value="applyingGlobalFilter"
          >
            <v-progress-circular
              indeterminate
              color="white"
            />
          </v-overlay>

          <v-divider class="my-4 secondary" />

          <strong class="mb-3 d-inline-block">SIDEBAR FILTERS</strong>

          <v-item-group v-model="color">
            <v-item
              v-for="color in colors"
              :key="color"
              :value="color"
            >
              <template v-slot="{ active, toggle }">
                <v-avatar
                  :class="active && 'v-settings__item--active'"
                  :color="color"
                  class="v-settings__item"
                  size="25"
                  @click="toggle"
                />
              </template>
            </v-item>
          </v-item-group>

          <v-divider class="my-4 secondary" />

          <strong class="mb-3 d-inline-block">SIDEBAR BACKGROUND</strong>

          <v-item-group v-model="scrim">
            <v-item
              v-for="scrim in scrims"
              :key="scrim"
              :value="scrim"
              class="mx-1"
            >
              <template v-slot="{ active, toggle }">
                <v-avatar
                  :class="active && 'v-settings__item--active'"
                  :color="scrim"
                  class="v-settings__item"
                  size="24"
                  @click="toggle"
                />
              </template>
            </v-item>
          </v-item-group>

          <v-divider class="my-4 secondary" />

          <v-row
            align="center"
            no-gutters
          >
            <v-col cols="auto">
              Dark Mode
            </v-col>

            <v-spacer />

            <v-col cols="auto">
              <v-switch
                v-model="$vuetify.theme.dark"
                class="ma-0 pa-0"
                color="secondary"
                hide-details
              />
            </v-col>
          </v-row>

          <v-divider class="my-4 secondary" />

          <v-row
            align="center"
            no-gutters
          >
            <v-col cols="auto">
              Sidebar Mini
            </v-col>

            <v-spacer />

            <v-col cols="auto">
              <v-switch
                v-model="internalValue"
                class="ma-0 pa-0"
                color="secondary"
                hide-details
              />
            </v-col>
          </v-row>

          <v-divider class="my-4 secondary" />

          <v-row
            align="center"
            no-gutters
          >
            <v-col cols="auto">
              Sidebar Image
            </v-col>

            <v-spacer />

            <v-col cols="auto">
              <v-switch
                v-model="showImg"
                class="ma-0 pa-0"
                color="secondary"
                hide-details
              />
            </v-col>
          </v-row>

          <v-divider class="my-4 secondary" />

          <strong class="mb-3 d-inline-block">IMAGES</strong>

          <v-item-group
            v-model="image"
            class="d-flex justify-space-between mb-3"
          >
            <v-item
              v-for="image in images"
              :key="image"
              :value="image"
              class="mx-1"
            >
              <template v-slot="{ active, toggle }">
                <v-sheet
                  :class="active && 'v-settings__item--active'"
                  class="d-inline-block v-settings__item"
                  @click="toggle"
                >
                  <v-img
                    :src="image"
                    height="100"
                    width="50"
                  />
                </v-sheet>
              </template>
            </v-item>
          </v-item-group>

          <div class="my-12" />

          <div>
            <strong class="mb-3 d-inline-block">THANK YOU FOR SHARING!</strong>
          </div>

          <v-btn
            class="ma-1"
            color="#55acee"
            dark
            default
            rounded
          >
            <v-icon>mdi-twitter</v-icon>
            - 45
          </v-btn>

          <v-btn
            class="ma-1"
            color="#3b5998"
            dark
            default
            rounded
          >
            <v-icon>mdi-facebook</v-icon>
            - 50
          </v-btn> -->
        </v-card-text>
      </v-card>
    </v-menu>
  </div>
</template>

<script>
  // Mixins

  import Proxyable from 'vuetify/lib/mixins/proxyable'
  import { createNamespacedHelpers } from 'vuex'

  const { mapGetters, mapMutations } = createNamespacedHelpers('app')

  export default {
    name: 'DashboardCoreSettings',

    mixins: [Proxyable],

    computed: {
      ...mapGetters(['globalAccounts', 'barImage', 'snCount', 'applyingGlobalFilter']),
      accounts () {
        const accountsList = []
        // console.log('global accounts are ')
        // console.log(this.globalAccounts)
        // accountsList.push({ acct: 'ALL', count :)
        if (this.globalAccounts) {
          this.globalAccounts.forEach((account) => {
            accountsList.push(account)
          })
        }
        return accountsList
      },
    },
    data: () => ({
      absolute: true,
      applyingFilter: false,
      inactive: false,
      color: '#E91E63',
      selectedAccount: 'ALL',
      fakeAccounts: [
        '#9C27b0',
        '#00CAE3',
        '#4CAF50',
        '#ff9800',
        '#E91E63',
        '#FF5252',
      ],

      colors: [
        '#9C27b0',
        '#00CAE3',
        '#4CAF50',
        '#ff9800',
        '#E91E63',
        '#FF5252',
      ],
      image: 'https://demos.creative-tim.com/material-dashboard-pro/assets/img/sidebar-1.jpg',
      images: [
        'https://demos.creative-tim.com/material-dashboard-pro/assets/img/sidebar-1.jpg',
        'https://demos.creative-tim.com/material-dashboard-pro/assets/img/sidebar-2.jpg',
        'https://demos.creative-tim.com/material-dashboard-pro/assets/img/sidebar-3.jpg',
        'https://demos.creative-tim.com/material-dashboard-pro/assets/img/sidebar-4.jpg',
      ],
      menu: false,
      saveImage: '',
      scrim: 'rgba(0, 0, 0, .7), rgba(0, 0, 0, .7)',
      scrims: [
        'rgba(0, 0, 0, .7), rgba(0, 0, 0, .7)',
        'rgba(228, 226, 226, 1), rgba(255, 255, 255, 0.7)',
        'rgba(244, 67, 54, .8), rgba(244, 67, 54, .8)',
      ],
      showImg: true,
    }),
    watch: {
      color (val) {
        this.$vuetify.theme.themes[this.isDark ? 'dark' : 'light'].primary = val
      },
      showImg (val) {
        if (!val) {
          this.saveImage = this.barImage
          this.setBarImage('')
        } else if (this.saveImage) {
          this.setBarImage(this.saveImage)
          this.saveImage = ''
        } else {
          this.setBarImage(val)
        }
      },
      image (val) {
        this.setBarImage(val)
      },
      scrim (val) {
        this.$store.commit('app/setScrim', val)
      },
      // globalAccounts: {
      //   handler() {
      //     console.log('global accounts updated')
      //     console.log(this.globalAccounts)
      //   }
      // }
    },
    mounted () {
      // console.log('mounted global accounts list is ')
      // console.log(this.globalAccounts)
      // get the selected account and set it....

    },
    methods: {
      ...mapMutations({
        setBarImage: 'setBarImage',
      }),
      selectAccount (account) {
        this.$gtag.event('selectAccount', { page: 'settings' })
        console.log('selected account ' + account)
        this.$store.dispatch('app/applyGlobalFilterByAccount', account)
      },
    },
  }
</script>

<style lang="sass">
  .v-settings
    .v-item-group > *
      cursor: pointer

    &__item
      border-width: 3px
      border-style: solid
      border-color: transparent !important

      &--active
        border-color: #00cae3 !important
</style>
